


















































































import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'
import AddPositionButton from '@/components/addPositionButton/AddPositionButton.vue'
import CurrencyImage from '@/components/currencyImage/CurrencyImage.vue'
import mixins from 'vue-typed-mixins'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default mixins(numberFormatMixin).extend({
    components: {
        CurrencyImage,
        AddPositionButton,
    },
    data() {
        return {
            portfolio: {} as any,
            positions: [],

            fields: [
                // A column that needs custom formatting
                {
                    key: 'name',
                    label: 'Name',
                },
                {
                    key: 'price',
                    label: 'Price',
                },
                {
                    key: 'holdings',
                    label: 'Holdings',
                    sortable: true,
                },
                {
                    key: 'avgPrice',
                    label: 'Avg. Buy Price',
                },
                {
                    key: 'unrealizedPNL',
                    label: 'Unrealized PNL',
                    sortable: true,
                },
                {
                    key: 'proportion',
                    label: 'Proportion',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
        }
    },
    computed: {
        breadcrumbs() {
            return [
                {
                    text: 'Home',
                    to: { name: 'home' },
                },
                {
                    // @ts-ignore
                    text: this.portfolio?.title,
                    active: true,
                },
            ]
        },
    },
    methods: {
        removePosition(position: any): void {
            authorizedRequest().delete(API_ROUTES.PORTFOLIOS.DELETE_POSITION + position.id)
                .then((response) => {
                    return this.reload()
                })
                .catch((e) => console.error(e))
        },
        closePosition(position: any): void {
            authorizedRequest().delete(API_ROUTES.PORTFOLIOS.CLOSE_POSITION + position.id)
                .then((response) => {
                    return this.reload()
                })
                .catch((e) => console.error(e))
        },
        loadPortfolio(): void {
            authorizedRequest().get(API_ROUTES.PORTFOLIOS.GET_SINGLE + this.$route.params.portfolioId)
                .then((response) => {
                    this.portfolio = response.data
                    this.positions = response.data.positions
                })
                .catch((e) => console.error(e))
        },
        reload(): void {
            this.loadPortfolio()
        },
        getUnrealizedProfitPercent(position: any): number {
            const unrealizedProfit = this.getUnrealizedProfit(position)
            const initialPositionSize = this.getInitialPositionSizeInUsd(position)
            return unrealizedProfit * 100 / initialPositionSize
        },
        getUnrealizedProfit(position: any): number {
            const currentPositionSize = this.getCurrentPositionSizeInUsd(position)
            const initialPositionSize = this.getInitialPositionSizeInUsd(position)
            return currentPositionSize - initialPositionSize
        },
        getProportion(position: any): number {
            return this.getCurrentPositionSizeInUsd(position) * 100 / this.portfolio.size
        },
        getCurrentPositionSizeInUsd(position: any): number {
            return position.amount * position.currency.price
        },
        getInitialPositionSizeInUsd(position: any): number {
            return position.amount * position.price
        },
        sortCompare(firstPosition: any, secondPosition: any, key: string) {
            let firstValue
            let secondValue

            switch (key) {
                case 'holdings':
                    firstValue = this.getCurrentPositionSizeInUsd(firstPosition)
                    secondValue = this.getCurrentPositionSizeInUsd(secondPosition)
                    break
                case 'unrealizedPNL':
                    firstValue = this.getUnrealizedProfit(firstPosition)
                    secondValue = this.getUnrealizedProfit(secondPosition)
                    break
                case 'proportion':
                    firstValue = this.getProportion(firstPosition)
                    secondValue = this.getProportion(secondPosition)
                    break
                default:
                    throw new Error(`Invalid sort key ${key}`)
            }

            return firstValue < secondValue ? -1 : firstValue > secondValue ? 1 : 0
        },
    },
    mounted() {
        this.loadPortfolio()
    },
})
