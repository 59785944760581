
































import { authorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'
import AddTransactionModal from '@/components/addTransactionModal/AddTransactionModal.vue'
import CurrencyImage from '@/components/currencyImage/CurrencyImage.vue'
import mixins from 'vue-typed-mixins'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default mixins(numberFormatMixin).extend({
    components: {
        AddTransactionModal,
        CurrencyImage,
    },
    props: {
        portfolioId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showSelectCoinModal: false,
            showAddNewTransactionModal: false,
            currencySearch: '',
            currencies: [],
            currency: {} as any,
            position: {} as any,
        }
    },
    methods: {
        openSelectCoinModal() {
            this.showSelectCoinModal = true
        },
        searchCurrency() {
            authorizedRequest().get(API_ROUTES.CURRENCIES.SEARCH, {
                params: {
                    search: this.currencySearch,
                },
            })
                .then((response) => {
                    this.currencies = response.data
                })
                .catch((e) => console.error(e))
        },
        selectCurrency(currency: any) {
            this.showAddNewTransactionModal = true
            this.showSelectCoinModal = false
            this.currency = currency

            authorizedRequest().post(API_ROUTES.PORTFOLIOS.ADD_POSITION, {
                portfolio_id: this.portfolioId,
                currency_id: this.currency!.id,
            })
                .then((response) => {
                    this.position = response.data
                })
                .catch((e) => {
                    console.error(e)
                })
        },
        onTransactionCreate() {
            this.$emit('create')
        },
    },
})
